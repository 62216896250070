import { useQuery } from '@tanstack/react-query'
import { restApi } from 'helpers'
import { useAuthContext } from 'contexts'
import { useProfileMe } from 'hooks'

const mockData = [
  {
    position: 1,
    address: '0x78A9D33B78d22cC64F9bC1Cf3352Ac094E50c0a9',
    jettons: 1031231032,
    isUser: false,
  },
  {
    position: 2,
    address: '0x78A9D33B78d22cC64F9bC1Cf3352Ac094E50c0a9',
    jettons: 30210010,
    isUser: false,
  },
  {
    position: 3,
    address: '0x78A9D33B78d22cC64F9bC1Cf3352Ac094E50c0a9',
    jettons: 20150069,
    isUser: false,
  },
  {
    position: 4,
    address: '0x78A9D33B78d22cC64F9bC1Cf3352Ac094E50c0a9',
    jettons: 9070069,
    isUser: false,
  },
  {
    position: 5,
    address: '0x78A9D33B78d22cC64F9bC1Cf3352Ac094E50c0a9',
    jettons: 6647047,
    isUser: true,
  },
  {
    position: 6,
    address: '0x78A9D33B78d22cC64F9bC1Cf3352Ac094E50c0a9',
    jettons: 1124013,
    isUser: false,
  },
]

const useLeaderboard = () => {
  const { address } = useAuthContext()

  return useQuery({
    queryKey: ['leaderboard', address],
    queryFn: async () => {
      const [leaderboardData, userApiData] = await Promise.allSettled([
        // TODO there is no endpoint for leaderboard - added on 07.10.2024 by sonatskiy
        restApi.get('/users/1/top?sortField=profit_passive'),
        address ? restApi.get('/profile/top?sortField=profit_passive') : Promise.resolve(),
      ])

      const userData = {
        position: 5,
        address: '0x78A9D33B78d22cC64F9bC1Cf3352Ac094E50c0a9',
        jettons: 47047,
      }

      const top = mockData.map((item) => ({
        ...item,
        isUser: userData.position === item.position,
      }))

      const isUserOutOfTop = userData.position > 10

      return {
        top,
        userData,
        isUserOutOfTop,
      }
    },
    refetchOnWindowFocus: false,
  })
}

export default useLeaderboard
