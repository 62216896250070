'use client'

import Image from 'next/image'
import { Button, buttonMessages } from 'components/inputs'
import bgTileImage from 'public/images/bg-tile.png'
import suitcase from 'public/images/treasure-suitcase.png'
import ConnectButtonWrapper from 'compositions/ConnectButtonWrapper/ConnectButtonWrapper'
import { openModal } from '@locmod/modal'

type IncreaseIncomeBannerProps = {
  className?: string
}

const style = {
  textShadow:
    '2px 2px 0px #1a1a1c, -2px 2px 0px #1a1a1c, 2px -2px 0px #1a1a1c, -2px -2px 0px #1a1a1c, 0px 2px 0px #1a1a1c, 0px -2px 0px #1a1a1c, 2px 0px 0px #1a1a1c, -2px 0px 0px #1a1a1c',
}

const IncreaseIncomeBanner: React.FC<IncreaseIncomeBannerProps> = ({ className }) => {
  return (
    <div className={className}>
      <div
        style={{ backgroundImage: `url(${bgTileImage.src})`, backgroundSize: '436px auto' }}
        className="mt-16 flex flex-col rounded-10 pb-4 lg:h-[368px] lg:flex-row lg:pl-[115px]"
      >
        <Image
          src={suitcase}
          alt="a suitcase of treasure"
          className="-mt-16 lg:order-last lg:h-[420px] lg:w-auto"
        />
        <div className="lg:-mt-[76px]">
          {/* TODO: Replace text with a picture after approval*/}
          <h2
            style={style}
            className="text-center text-px-44 font-black leading-none text-white lg:text-left lg:text-[114px]"
          >
            Boost your income
          </h2>
          <div className="px-4">
            <ConnectButtonWrapper>
              <Button
                title={buttonMessages.makeDeposit}
                styling="second"
                size="big"
                leftIcon="ui/pig"
                className="mt-2 justify-center mb:w-full lg:mt-6"
                onClick={() => openModal('DepositModal')}
              />
            </ConnectButtonWrapper>
          </div>
        </div>
      </div>
    </div>
  )
}

export default IncreaseIncomeBanner
