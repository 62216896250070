export default {
  title: {
    en: 'Where fun meets fortune!',
  },
  firstCardTitle: {
    en: 'Open the GiftBox',
  },
  firstCardDescription: {
    en: 'What’s waiting inside?<br/>Only one way to find out!<br/>Unbox a legendary surprise and enjoy the thrill of premium drops. We guarantee fairness, so the fun never stops.',
  },
  secondCardTitle: {
    en: 'Get Drops',
  },
  secondCardDescription: {
    en: "Get your OWN drop every day.<br/>Forget the hunt for the right one—we've been doing this for a while. Now it's your turn, and we'll show you the way!",
  },
  thirdCardTitle: {
    en: 'Deposit',
  },
  thirdCardDescription: {
    en: 'Take the first step into the world of Piggy Banks and start earning effortlessly. Sit back, relax, and watch your Jettons grow.',
  },
}
