export default {
  title: {
    en: 'Start earning Jettons today',
  },
  pool: {
    en: 'Your safe and reliable pool',
  },
  deposit: {
    en: 'Deposit and unstake anytime',
  },
  reward: {
    en: 'Rewards every 24 hours',
  },
  money: {
    en: 'Minimum entry  – $10',
  },
  safety: {
    en: 'External security audits',
  },
  code: {
    en: 'Open-source code',
  },
}
