'use client'

import clsx from 'clsx'
import { shortenAddress } from 'helpers'
import Image from 'next/image'
import { Message } from '@locmod/intl'
import messages from './messages'

const WINNER_DATA = [
  {
    address: '0x78A9D33B78d22cC64F9bC1Cf3352Ac094E50c0a9',
    amountWon: 100,
    currency: 'TON',
  },
  {
    address: '0x78A9D33B78d22cC64F9bC1Cf3352Ac094E50c0a9',
    amountWon: 100,
    currency: 'TON',
  },
  {
    address: '0x78A9D33B78d22cC64F9bC1Cf3352Ac094E50c0a9',
    amountWon: 100,
    currency: 'TON',
  },
  {
    address: '0x78A9D33B78d22cC64F9bC1Cf3352Ac094E50c0a9',
    amountWon: 100,
    currency: 'TON',
  },
  {
    address: '0x78A9D33B78d22cC64F9bC1Cf3352Ac094E50c0a9',
    amountWon: 100,
    currency: 'TON',
  },
  {
    address: '0x78A9D33B78d22cC64F9bC1Cf3352Ac094E50c0a9',
    amountWon: 100,
    currency: 'TON',
  },
]

type LastWinsListProps = {
  className?: string
}

type WinnerCardProps = {
  tokenImage: string
  address: string
  amountWon: number
  currency: string
}

const WinnerCard: React.FC<WinnerCardProps> = ({ tokenImage, address, amountWon, currency }) => {
  // TODO: Take token picture from the list of connected tokens
  return (
    <div
      className={clsx(
        'dashed-hover-border flex min-w-60 shrink-0 items-center gap-4 rounded-6 bg-[#4079d5] p-4 text-white hover:bg-[#4a83df] lg:min-w-[292px]',
      )}
    >
      <Image src={tokenImage} alt={currency} className="h-12 w-12 rounded-full" />
      <div className="flex flex-col">
        <span className="space-x-1.5 whitespace-nowrap text-px-12 font-medium uppercase leading-150p">
          <span>{shortenAddress(address, 4)}</span>
          <Message value={messages.won} />
        </span>
        <span className="text-px-20 font-bold uppercase leading-150p">
          {amountWon} {currency}
        </span>
      </div>
    </div>
  )
}

const LastWinsList: React.FC<LastWinsListProps> = ({ className }) => {
  return (
    <div
      className={clsx(
        className,
        'space-y-4 lg:mx-10 lg:rounded-6 lg:border lg:border-dashed lg:border-white lg:py-4',
      )}
    >
      <Message
        value={messages.title}
        tag="h4"
        className="px-4 font-source-serif text-px-24 leading-130p text-grey-800"
      />
      <div className="no-scrollbar flex gap-2 overflow-x-auto px-4 lg:gap-3">
        {WINNER_DATA.map(({ address, amountWon, currency }, index) => (
          <WinnerCard
            key={index}
            tokenImage=""
            address={address}
            amountWon={amountWon}
            currency={currency}
          />
        ))}
      </div>
    </div>
  )
}

export default LastWinsList
