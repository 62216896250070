'use client'

import clsx from 'clsx'
import { Message } from '@locmod/intl'
import messages from './messages'
import Image, { StaticImageData } from 'next/image'
import caseOne from 'public/images/cases/case-1.png'
import caseTwo from 'public/images/cases/case-2.png'
import caseThree from 'public/images/cases/case-3.png'
import caseElite from 'public/images/cases/case-3-big.png'
import caseThreeSmall from 'public/images/cases/case-3-small.png'
import { toLocaleString } from 'helpers'
import { Icon } from 'components/ui'
import { Button, buttonMessages } from 'components/inputs'
import { openModal } from '@locmod/modal'

type OpenCaseSectionProps = {
  className?: string
}

type CaseCardProps = {
  title: string
  description: string
  styling: 'default' | 'elite' | 'wide'
  jettons: number
  caseImage: StaticImageData
  className?: string
}

const CaseCard: React.FC<CaseCardProps> = ({
  title,
  description,
  styling,
  caseImage,
  jettons,
  className,
}) => {
  const lootbox = {
    image: caseImage,
    title,
    description,
    price: jettons,
  }

  return (
    <div
      className={clsx(
        className,
        'dashed-hover-border flex shrink-0 items-center gap-2 rounded-4 p-4 lg:rounded-5 lg:p-6',
        styling === 'default' && 'w-[15.25rem] flex-col bg-white lg:w-[324px] lg:gap-4',
        styling === 'elite' &&
          'flex-col bg-accent-yellow lg:h-[292px] lg:flex-wrap lg:justify-center lg:gap-6 lg:py-0 lg:pl-6 lg:pr-10',
        styling === 'wide' && 'flex-row gap-4 bg-white lg:h-[108px]',
      )}
    >
      <div className={clsx('ab', styling !== 'elite' && 'hidden')} />
      <Image
        src={caseImage}
        alt="case"
        className={clsx(
          styling === 'default' && 'size-[11.25rem] lg:size-[220px]',
          styling === 'elite' && 'size-[12.5rem] lg:size-[292px]',
          styling === 'wide' && 'order-first size-[12.5rem] lg:size-[94px]',
        )}
      />
      <div
        className={clsx(
          styling === 'default' && '-mt-15',
          styling === 'elite' && 'mt-6',
          'flex items-center gap-2 rounded-4 border border-dashed border-grey-850/50 bg-white p-2 text-px-14 font-bold leading-130p lg:rounded-5',
        )}
      >
        <Icon name="ui/coin" className="size-6" />
        <div className="space-x-1">
          <span>{toLocaleString(jettons, { digits: 0 })}</span>
          <Message value={messages.jettons} />
        </div>
      </div>
      <div
        className={clsx(
          styling === 'wide' && '-order-1 ml-1 flex-1 items-start',
          'flex flex-col gap-1',
        )}
      >
        <Message
          value={title}
          className="text-center text-px-20 font-black leading-150p lg:text-px-24"
        />
        <Message value={description} className="text-center text-px-14 font-medium leading-150p" />
      </div>
      <Button
        size="s"
        styling="main"
        title={buttonMessages.openCase}
        className={clsx(styling === 'elite' && 'mb:btn-size-big', 'lg:btn-size-big')}
        onClick={() => {
          openModal('LootboxModal', { lootbox })
        }}
      />
    </div>
  )
}

const OpenCaseSection: React.FC<OpenCaseSectionProps> = ({ className }) => {
  return (
    <section className={clsx(className, 'space-y-4 lg:space-y-7')}>
      <Message
        value={messages.title}
        tag="h2"
        className="px-4 font-source-serif text-px-40 leading-140p text-grey-800 lg:px-10"
      />
      <div className="flex gap-2 mb:flex-col lg:gap-5 lg:px-10">
        <div className="flex-1 space-y-5 mb:px-4">
          <CaseCard
            title="Elite"
            description="Unbox the best NFTs and memecoins"
            styling="elite"
            caseImage={caseElite}
            jettons={3000}
          />
          <CaseCard
            title="For a Rainy day"
            description="3 random items"
            styling="wide"
            jettons={1000}
            caseImage={caseThreeSmall}
            className="mb:hidden"
          />
        </div>
        <div className="no-scrollbar flex gap-2 overflow-x-auto mb:px-4 lg:gap-5">
          <CaseCard
            title="Coolio"
            description="Unbox the best NFTs"
            styling="default"
            caseImage={caseTwo}
            jettons={2000}
          />
          <CaseCard
            title="Memio"
            description="Unbox the best memecoins"
            styling="default"
            caseImage={caseOne}
            jettons={2000}
          />
          <CaseCard
            title="For a Rainy day"
            description="3 предмета"
            styling="default"
            jettons={1000}
            caseImage={caseThree}
            className="lg:hidden"
          />
        </div>
      </div>
    </section>
  )
}

export default OpenCaseSection
