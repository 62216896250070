'use client'

import messages from './messages'
import Image from 'next/image'
import { Message } from '@locmod/intl'
import { Button, buttonMessages } from 'components/inputs'
import heroBannerCoverMobile from 'public/images/hero/hero-banner-cover-mobile.png'
import heroBannerPigMobile from 'public/images/pigs/money-bath-small.png'
import heroBannerHeadline from 'public/images/hero/hero-banner-headline.png'
import heroBannerCover from 'public/images/hero/hero-banner-cover.png'
import heroBannerPig from 'public/images/pigs/money-bath.png'
import clsx from 'clsx'
import ConnectButtonWrapper from 'compositions/ConnectButtonWrapper/ConnectButtonWrapper'
import { openModal } from '@locmod/modal'

type HeroSectionProps = {
  className?: string
}

const HeroSection: React.FC<HeroSectionProps> = ({ className }) => {
  return (
    <>
      {/* Mobile */}
      <section
        className={clsx(
          className,
          'relative flex flex-col items-center px-4 text-center lg:hidden',
        )}
      >
        <Image
          src={heroBannerCoverMobile}
          alt="these are our piggy banks"
          className="w-82 z-10 h-64"
        />
        <Image
          src={heroBannerPigMobile}
          alt="pig in bath with money"
          className="absolute top-1 z-0 size-fit h-36 animate-float-down-small"
        />
        <Message
          value={messages.description}
          tag="p"
          className="mt-2 text-px-16 text-grey-800 lg:text-px-18"
        />
        <ConnectButtonWrapper>
          <Button
            title={buttonMessages.makeDeposit}
            styling="second"
            size="s"
            leftIcon="ui/pig"
            className="mt-4"
            onClick={() => openModal('DepositModal')}
          />
        </ConnectButtonWrapper>
      </section>
      {/* Desktop */}
      <section
        className={clsx(className, 'mx-auto flex max-w-[1100px] items-center gap-4 mb:hidden')}
      >
        <div>
          <Image
            src={heroBannerHeadline}
            alt="these are our piggy banks"
            className="size-fit h-[191px]"
          />
          <Message
            value={messages.description}
            tag="p"
            className="mt-4 text-px-16 text-grey-800 lg:text-px-18"
          />
          <ConnectButtonWrapper>
            <Button
              title={buttonMessages.makeDeposit}
              styling="second"
              size="big"
              leftIcon="ui/pig"
              className="mt-4"
              onClick={() => openModal('DepositModal')}
            />
          </ConnectButtonWrapper>
        </div>
        <div className="relative shrink-0">
          <Image
            src={heroBannerCover}
            alt="these are our piggy banks"
            className="z-10 h-[448px] w-[415px]"
          />
          <Image
            src={heroBannerPig}
            alt="pig in bath with money"
            className="absolute top-6 z-0 h-[360px] w-[411px] animate-float-down"
          />
        </div>
      </section>
    </>
  )
}

export default HeroSection
