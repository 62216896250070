'use client'

import Image from 'next/image'
import { Button, buttonMessages } from 'components/inputs'
import moneySuitcase from 'public/images/pigs/money-suitcase.png'
import coinsPig from 'public/images/pigs/coins.png'
import calculationsPig from 'public/images/pigs/calculations.png'
import messages from './messages'
import { Message } from '@locmod/intl'
import clsx from 'clsx'
import ConnectButtonWrapper from 'compositions/ConnectButtonWrapper/ConnectButtonWrapper'
import { openModal } from '@locmod/modal'

type FeaturesSectionProps = {
  className?: string
}

const FeaturesSection: React.FC<FeaturesSectionProps> = ({ className }) => {
  return (
    <section className={clsx(className, 'lg:mx-auto lg:max-w-[1328px]')}>
      <Message
        value={messages.title}
        tag="h2"
        className="text-center font-source-serif text-px-32 leading-120p text-grey-800 mb:px-4 lg:text-px-40 lg:leading-14"
      />
      <div className="no-scrollbar mt-3 flex max-w-full items-center overflow-x-auto overflow-y-hidden py-3 mb:px-4 lg:mb-2 lg:overflow-visible">
        <div className="z-10 min-w-60 rounded-5 bg-white p-1 shadow-[0_2px_6px_0_rgba(0,0,0,0.15)] lg:w-[620px] lg:p-2.5">
          <div className="flex flex-col items-center rounded-4 border border-black p-4 pt-2 lg:flex-row lg:items-start lg:gap-6 lg:px-5 lg:py-11">
            <Image
              src={moneySuitcase}
              alt="a pig with a suitcase of money"
              className="h-auto w-40 lg:h-[240px] lg:w-[240px]"
            />
            <div className="mb:mt-2 lg:flex lg:flex-col lg:gap-4">
              <Message
                value={messages.firstCardTitle}
                tag="h3"
                className="font-source-serif text-px-24 leading-130p mb:text-center"
              />
              <Message
                value={messages.firstCardDescription}
                tag="p"
                className="mt-1 text-px-13 leading-140p text-black/80 mb:text-center lg:text-px-16 lg:font-medium lg:leading-150p lg:text-black"
                html
              />
              <div className="flex gap-4 mb:hidden">
                <ConnectButtonWrapper>
                  <Button
                    title={buttonMessages.makeDeposit}
                    styling="second"
                    size="s"
                    leftIcon="ui/pig"
                    onClick={() => openModal('DepositModal')}
                  />
                </ConnectButtonWrapper>
                {/* TODO: Change button title using intl */}
                <Button title="Show giftbox" styling="third" size="s" />
              </div>
            </div>
          </div>
        </div>
        <div className="min-w-60 rotate-[4deg] rounded-5 bg-white p-1 shadow-[0_2px_6px_0_rgba(0,0,0,0.15)] mb:-ml-3 lg:w-[340px] lg:rotate-[8deg] lg:p-2.5">
          <div className="flex flex-col items-center rounded-4 border border-black p-4 pt-2 lg:px-5 lg:py-11">
            <Image
              src={coinsPig}
              alt="a pig with coins around it"
              className="h-auto w-40 lg:h-[200px] lg:w-[200px]"
            />
            <div className="mt-2 lg:mt-4">
              <Message
                value={messages.secondCardTitle}
                tag="h3"
                className="text-center font-source-serif text-px-24 leading-130p"
              />
              <Message
                value={messages.secondCardDescription}
                tag="p"
                className="mt-1 text-center text-px-13 leading-140p text-black/80 lg:mt-4 lg:text-px-16 lg:font-medium lg:leading-150p lg:text-black"
                html
              />
            </div>
          </div>
        </div>
        <div className="z-10 min-w-60 -rotate-2 transform rounded-5 bg-white p-1 shadow-[0_2px_6px_0_rgba(0,0,0,0.15)] mb:-ml-4 lg:order-first lg:w-[340px] lg:-rotate-6 lg:p-2.5">
          <div className="flex flex-col items-center rounded-4 border border-black p-4 pt-2 lg:px-5 lg:py-11">
            <Image
              src={calculationsPig}
              alt="a pig does the calculations"
              className="h-auto w-40 lg:h-[200px] lg:w-[200px]"
            />
            <div className="mt-2 lg:mt-4">
              <Message
                value={messages.thirdCardTitle}
                tag="h3"
                className="text-center font-source-serif text-px-24 leading-130p"
              />
              <Message
                value={messages.thirdCardDescription}
                tag="p"
                className="mt-1 text-center text-px-13 leading-140p text-black/80 lg:mt-4 lg:text-px-16 lg:font-medium lg:leading-150p lg:text-black"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="mt-6 flex items-center justify-center gap-4 lg:hidden">
        <ConnectButtonWrapper>
          <Button
            title={buttonMessages.makeDeposit}
            styling="second"
            size="s"
            leftIcon="ui/pig"
            onClick={() => openModal('DepositModal')}
          />
        </ConnectButtonWrapper>
        {/* TODO: Change button title using intl */}
        <Button title="Show giftbox" styling="third" size="s" />
      </div>
    </section>
  )
}

export default FeaturesSection
